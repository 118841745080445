var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "jadwal-kelas-selection h-screen"
  }, [_c('p', {
    staticClass: "font-bold text-3xl"
  }, [_vm._v("Create New Class Schedule")]), _c('div', {
    staticClass: "my-32"
  }, [_c('div', {
    staticClass: "flex justify-center gap-8 items-center mb-8"
  }, [_c('router-link', {
    staticClass: "selection border-yellow-primary p-8 bg-white rounded-2xl",
    attrs: {
      "to": "/jadwalkelas/add"
    }
  }, [_c('div', {
    staticClass: "flex h-32 w-32 justify-center items-center"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/webinar.png")
    }
  })]), _c('p', {
    staticClass: "mt-4 text-center font-semibold text-lg text-yellow-primary"
  }, [_vm._v(" Webinar ")])]), _c('router-link', {
    staticClass: "selection border-yellow-primary p-8 text-center bg-white rounded-2xl",
    attrs: {
      "to": "/jadwalkelas/new-schema"
    }
  }, [_c('div', {
    staticClass: "flex h-32 w-32 justify-center items-center"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/new-schema.png")
    }
  })]), _c('p', {
    staticClass: "mt-4 text-center font-semibold text-lg text-yellow-primary"
  }, [_vm._v(" New Schema ")])])], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Back",
      "type": "primary"
    },
    on: {
      "action": function action($event) {
        return _vm.goToListPage();
      }
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }